.amplify-button[data-variation='primary'] {
  background-color: #3949ab;
  width: 80%;
  align-self: center;
  margin-top: 5%;
  padding: 2%;
}
.amplify-button:hover[data-variation='primary'] {
  background-color: #1a237e;
}
