.circle-marker {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  text-align: center;
  line-height: 24px;
  font-size: 20px;
  font-style: bold;
}

.circle-marker-text {
  font-size: 20px;
  font-style: bold;
}

.circle-marker-blue {
  border: solid 4px blue;
}

.circle-marker-orange {
  border: solid 4px orange;
}

.circle-marker-red {
  border: solid 4px red;
}

.error_marker {
  animation: flash 1s linear infinite;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(253, 19, 19);
  opacity: 0%;
}
@keyframes flash {
  0% {
    opacity: 50%;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 50%;
  }
}
